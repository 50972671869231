<template>
    <p :class="classes">
        <slot />
    </p>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            required: false,
            default: () => "default",
        },
    },
    data() {
        return {
            baseClasses: "text-sm",
            types: {
                default: "text-gray-300",
                error: "text-red-600",
            },
        };
    },
    computed: {
        classes() {
            return `text ${this.baseClasses} ${this.types[this.type]}`;
        },
    },
};
</script>
